import React from "react";
import axios from "axios";
import NoRecord from "../../../common/NoRecord";
import Loader from "../../../common/Loader";
import Swal from "sweetalert2";
import Toast from "../../../common/SweetAlert.js";

export default function Query(props) {
  const [data, setData] = React.useState([]);
  const [equery, setEquery] = React.useState("");
  const [load, setLoad] = React.useState(false);

  React.useEffect(() => {
    axios.get("https://server.1000projects.com/query/retrieveAll").then((res) => {
      setData(res.data.reverse());
    });
  }, [load]);

  function deleteQuery(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get(`https://server.1000projects.com/query/delete/${id}`)
          .then((res) => {
            Toast.fire({
              icon: "success",
              title: res.data.message,
            });
            setLoad(true);
            setEquery("");
          });
      }
    });
  }

  return (
    <div className="col-12">
      <h4 class="fw-bold mb-4">
        <span class="text-muted fw-light">Home /</span> Query
      </h4>
      <div className="row">
        <div className="col-md-6 col-12">
          <div class="card">
            {!data ? (
              <Loader />
            ) : data.length === 0 ? (
              <h1 className="text-center">No Query</h1>
            ) : (
              <div className="table-responsive text-nowrap">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Query No</th>
                      <th>Name</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => {
                      return (
                        <tr>
                          <td>
                            {item.Query_ID}
                            <br />
                            <small>{item.Timestamp}</small>
                          </td>
                          <td>{item.Name}</td>
                          <td>
                            <button
                              onClick={() => setEquery(item)}
                              className="btn btn-primary btn-sm"
                            >
                              Expand
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
        <div className="col-md-6 col-12 mt-5">
          <div class="card">
            <div className="card-body">
              <h5
                class="card-title
              fw-bold"
              >
                Query Details
              </h5>
              {!equery && <h1 className="text-center">Click on Query to Expand</h1>}
              {equery && (
                <>

                  <dl className="row mt-2">
                    <dt className="col-sm-3">Query ID</dt>
                    <dd className="col-sm-9">{equery.Query_ID}</dd>
                    <dt className="col-sm-3">Timestamp</dt>
                    <dd className="col-sm-9">{equery.Timestamp}</dd>
                    <dt className="col-sm-3">Name</dt>
                    <dd className="col-sm-9">{equery.Name}</dd>
                    <dt className="col-sm-3">Email ID</dt>
                    <dd className="col-sm-9">{equery.Email}</dd>
                    <dt className="col-sm-3">Phone</dt>
                    <dd className="col-sm-9">{equery.Phone}</dd>
                    <dt className="col-sm-3 text-truncate">Query</dt>
                    <dd className="col-sm-9">{equery.Message}</dd>
                    <dt className="col-sm-3">Attachment</dt>
                    <dd className="col-sm-9">
                      {!equery.Attachment && "No Attachment"}
                      {equery.Attachment && (
                        <button
                          className="btn btn-primary btn-sm"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#attachmentModal"
                          onClick={() =>
                            props.setGlobal({ doc: equery.Attachment })
                          }
                        >
                          <i className="fa-solid fa-eye" /> Preview
                        </button>
                      )}

                    </dd>
                  </dl>
                  <button
                    className="btn btn-primary btn-sm m-1"
                    type="button"
                    style={{ float: "right" }}
                    onClick={() =>
                      deleteQuery(equery.Query_ID)
                    }
                  >
                    <i className="fa-solid fa-trash" /> Delete
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
