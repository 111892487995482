import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/img/icon/logo-nobg.png";

export default function Login(props) {
  const navigate = useNavigate();
  const [alert, setAlert] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [data, setData] = React.useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      data.email !== "" &&
      data.password !== "" &&
      data.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
    ) {
      axios
        .post("https://server.1000projects.com/auth/login", data)
        .then((res) => {
          setAlert(res.data.message);
          if (res.data.status === 0) {
            localStorage.setItem("token", res.data.token);
            props.setUser(res.data.user);
            navigate("/");
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    } else {
      setAlert("Incorrect OTP");
    }
  };

  return (
    <div className="container-xxl">
      <div className="authentication-wrapper authentication-basic container-p-y">
        <div className="authentication-inner">
          <div className="card">
            <div className="card-body">
              <div className="app-brand justify-content-center">
                <img
                  className="app-brand-logo"
                  src={Logo}
                  alt="logo"
                  height={"100px"}
                />
              </div>
              <h4 className="mb-2">Welcome to 1000Projects! 👆</h4>
              <p className="mb-4">Please Login to your account</p>
              <p className="text-center" style={{ color: "red" }}>
                {alert}
              </p>
              <form className="mb-3">
                <>
                  <div className="mb-3">
                    <label className="form-label">Email</label>
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      onChange={handleChange}
                      value={data.email}
                      placeholder="Enter your Email Address"
                    />
                  </div>
                  <div className="mb-3 form-password-toggle">
                    <div className="d-flex justify-content-between">
                      <label className="form-label">Password</label>
                      <button onClick={() => navigate("/reset")}>
                        <small>Forgot Password?</small>
                      </button>
                    </div>
                    <div className="input-group input-group-merge">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        onChange={handleChange}
                        value={data.password}
                        name="password"
                        placeholder="Enter your Password"
                      />
                      <span className="input-group-text cursor-pointer">
                        <i
                          onClick={() => setShowPassword(!showPassword)}
                          className={showPassword ? "bx bx-show" : "bx bx-hide"}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="mb-3">
                    <button
                      onClick={handleSubmit}
                      className="btn btn-primary d-grid w-100"
                    >
                      Login Now
                    </button>
                  </div>
                </>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
