import React from "react";
import { useNavigate, Routes, Route } from "react-router-dom";
import Home from "./components/home/Home";
import Sidebar from "./sidebar/Sidebar";
import Navbar from "./navbar/Navbar";
import Modal from "../common/Modal";
import Career from "./components/career/Career";
import Feedback from "./components/feedback/Feedback";
import Newsletter from "./components/newsletter/Newsletter";
import Projects from "./components/projects/Projects";
import PForm from "./components/projects/PForm";
import Query from "./components/query/Query";
import JForm from "./components/career/JForm";
import JobApplications from "./components/career/JApplications";

export default function Admin(props) {
  const [global, setGlobal] = React.useState({
    doc: "",
  });
  const hideSidebar = () => {
    document.querySelector("html").classList.remove("layout-menu-expanded");
  };

  return (
    <>
      {global.doc && <Modal global={global} setGlobal={setGlobal} />}
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Sidebar hideSidebar={hideSidebar} logout={props.logout} />
          <div className="layout-page">
            <Navbar user={props.user} logout={props.logout} />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <Routes>
                  <Route path="/*" element={<Home setGlobal={setGlobal} />} />
                  <Route
                    path="/projects"
                    element={<Projects setGlobal={setGlobal} />}
                  />
                  <Route
                    path="/projects/AddProject"
                    element={<PForm setGlobal={setGlobal} />}
                  />
                  <Route
                    path="/careers"
                    element={<Career setGlobal={setGlobal} />}
                  />
                  <Route
                    path="/careers/addJob"
                    element={<JForm setGlobal={setGlobal} />}
                  />
                  <Route
                    path="/jobApplications"
                    element={<JobApplications setGlobal={setGlobal} />}
                  />
                  
                  <Route
                    path="/feedback"
                    element={<Feedback setGlobal={setGlobal} />}
                  />
                  <Route
                    path="/newsletter"
                    element={<Newsletter setGlobal={setGlobal} />}
                  />

                  <Route
                    path="/queries"
                    element={<Query setGlobal={setGlobal} />}
                  />
                </Routes>
              </div>
            </div>
          </div>
        </div>
        <div
          onClick={hideSidebar}
          className="layout-overlay layout-menu-toggle"
        ></div>
      </div>
    </>
  );
}
