import React from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import NoRecord from "../../../common/NoRecord";
import Loader from "../../../common/Loader";
import Swal from "sweetalert2";
import Toast from "../../../common/SweetAlert.js";

export default function Career(props) {
  const navigate = useNavigate();
  const [jobs, setJobs] = React.useState([]);
  const [load, setLoad] = React.useState(false);

  React.useEffect(() => {
    axios.get("https://server.1000projects.com/job/All").then((res) => {
      setJobs(res.data);
    });
  }, [load]);

  function deleteJob(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get(`https://server.1000projects.com/job/delete/${id}`)
          .then((res) => {
            Toast.fire({
              icon: "success",
              title: "Job Deleted Successfully",
            });
            setLoad(true);
          });
      }
    });
  }

  return (
    <div className="col-12">
      <h4 class="fw-bold mb-4">
        <span className="text-muted fw-light">Home /</span> Career
        <span style={{ float: "right" }}>
          <button
            className="btn btn-primary btn-sm m-1"
            onClick={() => navigate("/jobApplications")}
          >
            {" "}
            Job Applications
          </button>
          <button
            className="btn btn-primary btn-sm m-1"
            onClick={() => navigate("/careers/addJob")}
          >
            <i className="fas fa-plus"></i> Add Job
          </button>
        </span>
      </h4>
      <div className="demo-inline-spacing mt-5">
        {!jobs ? (
          <Loader />
        ) : jobs.length === 0 ? (
          <NoRecord />
        ) : (
          <div className="list-group col-12">
            {jobs.map((job) => {
              return (
                <div className="list-group-item list-group-item-action flex-column align-items-start">
                  <div className="d-flex justify-content-between w-100 p-2">
                    <h5>{`${job.job_id} | ${job.title}`}</h5>
                    <small>{job.timestamp}</small>
                  </div>
                  <p className="mb-1">
                    <dl className="row mt-2">
                      <dt className="col-sm-2">Job Description</dt>
                      <dd className="col-sm-10">{job.description}</dd>
                      <dt className="col-sm-2">Skills Required</dt>
                      <dd className="col-sm-10">{job.skills}</dd>
                      <dt className="col-sm-2">Experience</dt>
                      <dd className="col-sm-10">{job.experience}</dd>
                      <dt className="col-sm-2">Salary</dt>
                      <dd className="col-sm-10">{job.salary}</dd>
                      <dt className="col-sm-2">Location</dt>
                      <dd className="col-sm-10">{job.location}</dd>
                    </dl>
                    {job.jd && (
                      <button
                        className="btn btn-primary btn-sm"
                        type="button"
                        style={{ float: "right" }}
                        data-bs-toggle="modal"
                        data-bs-target="#attachmentModal"
                        onClick={() => props.setGlobal({ doc: job.jd })}
                      >
                        <i className="fa-solid fa-eye" /> Job Description
                      </button>
                    )}
                    <button
                      className="btn btn-primary btn-sm m-1"
                      type="button"
                      style={{ float: "right" }}
                      onClick={() => deleteJob(job.job_id)}
                    >
                      <i className="fa-solid fa-trash" /> Delete
                    </button>
                  </p>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
